package com.dev.san.models

import com.dev.san.util.Res

enum class Achievement(
    val icon: String,
    val number: Int,
    val description: String
) {
    Completed(
        icon = Res.Icon.checkmark,
        number = 50,
        description = "Completed Projects"
    ),
    Active(
        icon = Res.Icon.shield,
        number = 3,
        description = "Active Projects"
    ),
    Satisfied(
        icon = Res.Icon.happy,
        number = 10,
        description = "Satisfied Clients"
    ),
    Team(
        icon = Res.Icon.user,
        number = 3,
        description = "Team Members"
    )
}