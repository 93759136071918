package com.dev.san.models

import com.dev.san.util.Constants.KHAYA_265
import com.dev.san.util.Constants.NYASA_BLOG_DESCRIPTION
import com.dev.san.util.Constants.PANYUMBA_DESCRIPTION

enum class Experience(
    val number: String,
    val jobPosition: String,
    val description: String,
    val company: String,
    val from: String,
    val to: String
) {
    First(
        number = "01",
        jobPosition = "Android Developer",
        description = KHAYA_265,
        company = "Khaya265",
        from = "2021",
        to = "NOW",
    ),
    Second(
        number = "02",
        jobPosition = "Full Stack Software Engineer",
        description = NYASA_BLOG_DESCRIPTION,
        company = "NyasaBlog",
        from = "2019",
        to = "2021",
    ),
    Third(
        number = "03",
        jobPosition = "Android Developer",
        description = PANYUMBA_DESCRIPTION,
        company = "Panyumba",
        from = "2018",
        to = "2019",
    )
}