package com.dev.san.util

object Constants {
    const val KHAYA = "https://khaya265.com/"
    const val NYASABLOG = "https://nyasablog.com/"
    const val PORTFOLIO = "https://ephraimkanyandula.com"
    const val PLAY_STORE = "https://play.google.com/store/apps/details?id=com.dev.khaya265.house.app"


    const val FACEBOOK = "https://www.facebook.com/streetevangelist/"
    const val LINKEDIN ="https://www.linkedin.com/in/ephraim-kanyandula/"
    const val INSTAGRAM = "https://www.instagram.com/divineprophecy/"
    const val TWITTER ="https://twitter.com/EKanyandula"
    const val GITHUB = "https://github.com/Kanyandula"
    const val RESUME = "https://1drv.ms/b/s!AqWTy16JL-hOg7VjCpndrAErdO_UfQ?e=VsuSHu"



    const val SECTION_WIDTH = 1920
    const val FONT_FAMILY = "Roboto"

    //Profile
    const val ABOUT_ME ="Experienced Software Developer with expertise in design, develop, testing and maintenance of software systems. Equipped with a diverse and promising skill set. Proficient in various platforms, languages, and frameworks. Experienced with cutting-edge development tools and procedures. Able to effectively self-manage during independent projects, as well as collaborate as part of a productive team."
    const val ABOUT_ME_1 ="A malawian national living in Dublin, Ireland. Open-minded person, loves traveling, art, cooks excellent meals, loves to explore and learning about different cultures and traditions."

    //Home
    const val ABOUT_PROFILE = "Based in Wicklow, Ireland. I  am specialised in building highly performant applications that solve real-world problems and provide users with an awesome experience. I’m currently working using Android SDK (Kotlin), Django and Node.js"

    //Experience Description
    const val PANYUMBA_DESCRIPTION ="Real Estate native Android App for booking and renting student accommodations. Owned by Panyumba Accommodation, a company based in Zomba Malawi. Responsible for the whole project"
    const val NYASA_BLOG_DESCRIPTION ="Content Creators Blog Apps for both mobile and web applications. The web app was developed using the Django framework and the mobile application is a native Android app written using Kotlin programming language. I am responsible for the whole project from the front bends to the back end, the creation of REST APIs, managing the Nginx server, web hosting, and developing and publishing the mobile app."
    const val KHAYA_265 ="Native Android Real Estate application written using Kotlin programming language. Owned by Khaya265 Real Estate company. Worked with a team of 3 people using a waterfall software development methodology and I was a team lead. Responsible for the software system analysis and design."

    const val LOREM_IPSUM_SHORT =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."

    const val  SYSTEM_ANALYSIS = "Developing software systems by analysing the requirements of the project and designing solutions that meets the requirements"

    const val ANDROID_DEVELOPMENT ="Developing native Android application using  Kotlin or Java languages for 5 years plus.UI/UX design, software testing and maintenance  "

    const val WEB_DEVELOPER ="Full Stack Developer with 6 years of experience. Fluent in Node.js, Django, Web hosting, Cloud computing and CI/CD "
}


object Res {
    object Icon {
        const val web = "web_icon.svg"
        const val seo = "seo_icon.svg"
        const val design = "design_icon.svg"
        const val business = "business_icon.svg"
        const val apple = "apple_icon.svg"
        const val android = "android_icon.svg"
        const val link = "link_icon.svg"
        const val user = "user_icon.svg"
        const val shield = "shield_icon.svg"
        const val happy = "happy_icon.svg"
        const val checkmark = "checkmark_icon.svg"
        const val star = "star_icon.svg"
    }
    object Image {
        const val coding ="coding.png"
        const val background = "background.svg"
        const val logo = "logo.svg"
        const val main = "cover.png"
        const val portfolio1 = "portfolio1.png"
        const val portfolio2 = "portfolio2.png"
        const val portfolio3 = "portfolio3.png"
        const val portfolio4 = "portfolio4.png"
        const val portfolio5 = "portfolio5.png"
        const val avatar1 = "avatar1.png"
        const val avatar2 = "avatar2.png"
        const val avatar3 = "avatar3.png"
        const val avatar4 = "avatar4.png"
        const val avatar5 = "avatar5.png"
        const val avatar6 = "avatar6.png"
    }
}