package com.dev.ephraim.models

import com.dev.san.util.Constants.ANDROID_DEVELOPMENT
import com.dev.san.util.Constants.SYSTEM_ANALYSIS
import com.dev.san.util.Constants.WEB_DEVELOPER
import com.dev.san.util.Res

enum class Service(
    val icon: String,
    val imageDesc: String,
    val title: String,
    val description: String
) {
    Android(
        icon = Res.Icon.android,
        imageDesc = "Android Icon",
        title = "Android Development",
        description = ANDROID_DEVELOPMENT
    ),
    Web(
        icon = Res.Icon.web,
        imageDesc = "Desktop Icon",
        title = "Web Development",
        description = WEB_DEVELOPER
    ),
    Business(
        icon = Res.Icon.business,
        imageDesc = "Chart Icon",
        title = "System Analysis and Design",
        description = SYSTEM_ANALYSIS
    )

}