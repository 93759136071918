package com.dev.san.models

import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.rgb

enum class Theme(
    val hex: String,
    val rgb: CSSColorValue
) {
    Primary(hex = "#00ACF0", rgb = rgb(r = 0, g = 172, b = 240)),
    Secondary(hex = "#000000", rgb = rgb(r = 0, g = 0, b = 0)),
    Gray(hex = "#CFCFCF", rgb = rgb(r = 207, g = 207, b = 207)),
    LightGray(hex = "#EDEDED", rgb = rgb(r = 237, g = 237, b = 237)),
    LighterGray(hex = "#081b29", rgb = rgb(r = 249, g = 249, b = 249)),
    DarkBlue(hex = "#011559", rgb = rgb(r = 1, g = 22, b = 89))
}