package com.dev.san.models

import com.dev.san.util.Constants.GITHUB
import com.dev.san.util.Constants.KHAYA
import com.dev.san.util.Constants.NYASABLOG
import com.dev.san.util.Constants.PLAY_STORE
import com.dev.san.util.Constants.PORTFOLIO
import com.dev.san.util.Res

enum class Portfolio(
    val image: String,
    val title: String,
    val description: String,
    val link : String
) {
    One(
        image = Res.Image.portfolio1,
        title = " Landing Page for Khaya265",
        description = "Frontend",
        link = KHAYA
    ),
    Two(
        image = Res.Image.portfolio2,
        title = "Khaya265 Mobile App",
        description = "Android App",
        link = PLAY_STORE
    ),
    Three(
        image = Res.Image.portfolio3,
        title = "Github Profile ",
        description = "Software Projects",
        link = GITHUB
    ),
    Four(
        image = Res.Image.portfolio4,
        title = "NyasaBlog Web App and Rest API",
        description = "Web App",
        link = NYASABLOG
    ),
    Five(
        image = Res.Image.portfolio5,
        title = "Portfolio Website",
        description = "Website",
        link = PORTFOLIO
    )
}